<template>
  <div class="month-picker-background" v-click-outside="showRangeWindow">
    <label>
      {{ label }}
    </label>
    <input
      @focus.stop="checkValue"
      v-mask="monthRangeMask"
      @input="setValueMonthRange"
      type="text"
      :placeholder="placeholder"
      v-model="monthRangeValue"
      :class="{ 'invalid-input': invalid }"
      ref="month"
    />
    <small class="invalid-label" v-if="invalid">{{ userText }}</small>
    <div v-show="showRange" id="month-picker">
      <div class="month_picker_container">
        <div class="year_select_area">
          <img
            src="~@/assets/img/icons/arrowNextYear.svg"
            class="right_arrow arrow"
            @click="clickYear(0)"
          />
          <div class="now_year">{{ nowYear }}</div>
          <img src="~@/assets/img/icons/arrowNextYear.svg" class="arrow" @click="clickYear(1)" />
        </div>
        <div class="month_select_area">
          <ul class="month_list_area">
            <li
              v-for="(list, index) in month"
              class="month_list"
              :class="{
                'chosen-month': choseMonth === index && chosenYear === nowYear
                // 'disabled-month': disabledMonth(index)
              }"
              @click="clickMonth(list, index)"
              :key="list"
              :style="CheckMain(list, index)"
            >
              <span>{{ list }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

import { format } from "date-fns";

export default {
  name: "DateRangeMonth",
  mixins: [],
  props: {
    minDate: {
      type: String,
      default: "2000-01"
    },
    maxDate: {
      type: String,
      default: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1
      }`
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "Дата"
    },
    value: {
      type: String,
      default: ""
    },
    keyAdd: {
      type: String,
      require: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      nowYear: new Date().getFullYear(),
      month: ["ЯНВ", "ФЕВ", "МАР", "АПР", "МАЙ", "ИЮН", "ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК"],
      activeMonthFirst: "",
      activeMonthSecond: "",
      activeYearFirst: "",
      activeYearSecond: "",
      showRange: false,
      monthRangeValue: "",
      choseMonth: this.maxDate ? parseInt(this.maxDate.split("-")[0]) - 1 : new Date().getMonth(),
      chosenYear: this.maxDate ? parseInt(this.maxDate.split("-")[1]) : new Date().getFullYear(),
      monthRangeMask: {
        mask: "от 99-9999 до 99-9999",
        placeholder: "",
        showMaskOnHover: false
      }
    };
  },
  mounted() {},
  methods: {
    checkValue() {
      this.showRange = true;
    },
    showRangeWindow() {
      this.showRange = false;
    },
    /**
     * Функция emit которая в зависимости от параметра, в нужном расположении записывает данные в store
     * @param normal - Если true то записывает в обычном порядке, иначе меняет значения местами.
     */
    emitEvent(normal) {
      let firstMonth =
        this.activeMonthFirst.index + 1 < 10
          ? `0${this.activeMonthFirst.index + 1}`
          : this.activeMonthFirst.index + 1;
      let secondMonth =
        this.activeMonthSecond.index + 1 < 10
          ? `0${this.activeMonthSecond.index + 1}`
          : this.activeMonthSecond.index + 1;
      if (normal) {
        this.$emit("monthRange", {
          start: `${this.activeYearFirst}-${firstMonth}`,
          end: `${this.activeYearSecond}-${secondMonth}`
        });
      } else {
        this.$emit("monthRange", {
          end: `${this.activeYearFirst}-${firstMonth}`,
          start: `${this.activeYearSecond}-${secondMonth}`
        });
      }
    },
    /**
     * Отвечает за связь модалки выбора месяцев и инпута.
     */
    setValueMonthRange() {
      let minMonth = this.minDate.split("-")[1];
      let minYear = this.minDate.split("-")[0];
      let maxMonth = this.maxDate.split("-")[1];
      let maxYear = this.maxDate.split("-")[0];
      if (this.monthRangeValue.length === 21) {
        let firstMain = this.monthRangeValue.split(" ")[1].split("-").reverse();
        let secondMain = this.monthRangeValue.split(" ")[3].split("-").reverse();
        if (firstMain[0] > secondMain[0]) {
          let swap = secondMain;
          secondMain = firstMain;
          firstMain = swap;
        }
        if (firstMain[0] === secondMain[0]) {
          if (firstMain[1] > secondMain[1]) {
            let swap = secondMain;
            secondMain = firstMain;
            firstMain = swap;
          }
        }
        /**
         * Проверка первой введенной даты на валидность
         */
        if (firstMain[0] === minYear) {
          if (firstMain[1] < minMonth || firstMain[1] > 12) {
            firstMain[1] = minMonth;
          }
        } else if (firstMain[0] < minYear || firstMain[0] > maxYear) {
          firstMain[0] = minYear;
          if (firstMain[1] < minMonth || firstMain[1] > 12) {
            firstMain[1] = minMonth;
          }
        }
        if (firstMain[1] < 1) {
          firstMain[1] = "01";
        } else if (firstMain[1] > 12) {
          firstMain[1] = "12";
        }
        /**
         * Проверка второй введенной даты на валидность
         */
        if (secondMain[0] === maxYear) {
          if (secondMain[1] > maxMonth || secondMain[1] < 1) {
            secondMain[1] = maxMonth;
          }
        } else if (secondMain[0] > maxYear || secondMain[0] < minYear) {
          secondMain[0] = maxYear;
          if (secondMain[1] > maxMonth || secondMain[1] < 1) {
            secondMain[1] = maxMonth;
          }
        }
        if (secondMain[1] < 1) {
          secondMain[1] = "01";
        } else if (secondMain[1] > 12) {
          secondMain[1] = "12";
        }
        this.activeMonthFirst = {
          name: this.month[firstMain[1] - 1],
          index: firstMain[1] - 1
        };
        this.activeYearFirst = parseInt(firstMain[0]);
        this.activeMonthSecond = {
          name: this.month[secondMain[1] - 1],
          index: secondMain[1] - 1
        };
        this.activeYearSecond = parseInt(secondMain[0]);

        this.emitEvent(true);
        this.monthRangeValue = `${firstMain[1]}-${firstMain[0]} ${secondMain[1]}-${secondMain[0]}`;
        this.value = `от ${firstMain[0]}-${firstMain[1]} до ${secondMain[0]}-${secondMain[1]}`;
        this.showRange = false;
      } else {
        this.activeMonthFirst = "";
        this.activeMonthSecond = "";
        this.activeYearFirst = "";
        this.activeYearSecond = "";
      }
    },
    /**
     * Отвечает за переключение Годов.
     */
    clickYear(status) {
      if (status === 0) {
        if (this.nowYear > this.minDate.split("-")[0]) {
          this.nowYear--;
        }
      }
      if (status === 1) {
        if (this.nowYear < this.maxDate.split("-")[0]) {
          this.nowYear++;
        }
      }
    },
    /**
     * Отвечает за нажатие на Месяца, отмечает 1 и 2 активные(главные) точки.
     */
    clickMonth(list, index) {
      let minMonth = parseInt(this.minDate.split("-")[1]);
      let minYear = parseInt(this.minDate.split("-")[0]);
      let maxMonth = parseInt(this.maxDate.split("-")[1]);
      let maxYear = parseInt(this.maxDate.split("-")[0]);
      /**
       * Если нету 1 активной точки
       */
      if (!this.activeMonthFirst) {
        if (this.nowYear === minYear) {
          if (index + 1 >= minMonth) {
            this.activeMonthFirst = {
              name: list,
              index: index
            };
            this.activeYearFirst = this.nowYear;
          } else {
            return null;
          }
        } else if (this.nowYear === maxYear) {
          if (index + 1 <= maxMonth) {
            this.activeMonthFirst = {
              name: list,
              index: index
            };
            this.activeYearFirst = this.nowYear;
          } else {
            return null;
          }
        } else {
          this.activeMonthFirst = {
            name: list,
            index: index
          };
          this.activeYearFirst = this.nowYear;
        }
      } else if (!this.activeMonthSecond) {
        /**
         * Если нету 2 активной точки
         */
        if (this.nowYear === minYear) {
          if (index + 1 >= minMonth) {
            this.activeMonthSecond = {
              name: list,
              index: index
            };
            this.activeYearSecond = this.nowYear;
          } else {
            return null;
          }
        } else if (this.nowYear === maxYear) {
          if (index + 1 <= maxMonth) {
            this.activeMonthSecond = {
              name: list,
              index: index
            };
            this.activeYearSecond = this.nowYear;
          } else {
            return null;
          }
        } else {
          this.activeMonthSecond = {
            name: list,
            index: index
          };
          this.activeYearSecond = this.nowYear;
        }
        if (this.activeYearFirst > this.activeYearSecond) {
          this.emitEvent();
          this.monthRangeValue = this.getValidValue();
          this.showRange = false;
        } else if (this.activeYearFirst === this.activeYearSecond) {
          if (this.activeMonthFirst.index > this.activeMonthSecond.index) {
            this.emitEvent();
            this.monthRangeValue = this.getValidValue();
          } else {
            this.emitEvent(true);
            this.monthRangeValue = this.getValidValue(true);
          }
          this.showRange = false;
        } else {
          this.emitEvent(true);
          this.monthRangeValue = this.getValidValue(true);
          this.showRange = false;
        }
        this.value = this.monthRangeValue;
      } else {
        if (this.nowYear === minYear) {
          if (index + 1 >= minMonth) {
            this.activeMonthFirst = {
              name: list,
              index: index
            };
            this.activeYearFirst = this.nowYear;
          } else {
            return null;
          }
        } else if (this.nowYear === maxYear) {
          if (index + 1 <= maxMonth) {
            this.activeMonthFirst = {
              name: list,
              index: index
            };
            this.activeYearFirst = this.nowYear;
          } else {
            return null;
          }
        } else {
          this.activeMonthFirst = {
            name: list,
            index: index
          };
          this.activeYearFirst = this.nowYear;
        }
        this.activeMonthSecond = "";
        this.activeYearSecond = "";
      }
    },
    /**
     * Функция для получения валидной строки вида `{date} {date}`
     * @param normal - Если true, по получаем стандартное значение, иначе перевернутое.
     */
    getValidValue(normal) {
      if (normal) {
        return `${
          this.activeMonthFirst.index + 1 < 10
            ? `0${this.activeMonthFirst.index + 1}`
            : this.activeMonthFirst.index + 1
        }-${this.activeYearFirst} ${
          this.activeMonthSecond.index + 1 < 10
            ? `0${this.activeMonthSecond.index + 1}`
            : this.activeMonthSecond.index + 1
        }-${this.activeYearSecond}`;
      } else {
        return `${
          this.activeMonthSecond.index + 1 < 10
            ? `0${this.activeMonthSecond.index + 1}`
            : this.activeMonthSecond.index + 1
        }-${this.activeYearSecond} ${
          this.activeMonthFirst.index + 1 < 10
            ? `0${this.activeMonthFirst.index + 1}`
            : this.activeMonthFirst.index + 1
        }-${this.activeYearFirst}`;
      }
    },
    /**
     * Отвечает за отрисовку второстепенных месяцев, входящих в расстояние между главными точками.
     */
    checkBetween(index) {
      if (this.inFirstYear || this.inSecondYear) {
        /**
         * Если одна и таже дата нажата на разных годах.
         */
        if (this.ifFirstSameSecond) {
          if (this.activeYearFirst < this.activeYearSecond) {
            /**
             * Если месяц входит в диапозон между 1 и 2 точкой
             */
            if (
              (this.inFirstYear && index > this.activeMonthFirst.index) ||
              (this.inSecondYear && index < this.activeMonthSecond.index)
            ) {
              return "background: #F3F5FA; border-radius: 0";
            }
          } else if (this.activeYearFirst > this.activeYearSecond) {
            /**
             * Если месяц входит в диапозон между 1 и 2 точкой
             */
            if (
              (this.inFirstYear && index < this.activeMonthFirst.index) ||
              (this.inSecondYear && index > this.activeMonthSecond.index)
            ) {
              return "background: #F3F5FA; border-radius: 0";
            }
          }
        }
        if (this.activeMonthFirst && this.activeMonthSecond) {
          /**
           * Если две активные(main) точки находятся на одной станице.
           */
          if (this.activeYearFirst === this.activeYearSecond) {
            if (
              (index > this.activeMonthFirst.index && index < this.activeMonthSecond.index) ||
              (index < this.activeMonthFirst.index && index > this.activeMonthSecond.index)
            ) {
              return "background: #F3F5FA; border-radius: 0";
            }
          } else if (this.activeYearFirst !== this.activeYearSecond) {
            /**
             * Если две активные(main) точки находятся на разных страницах.
             */
            /**
             * Если на странице первой активной точки
             */
            if (this.inFirstYear) {
              if (
                this.activeYearFirst < this.activeYearSecond &&
                index > this.activeMonthFirst.index
              ) {
                return "background: #F3F5FA; border-radius: 0";
              } else if (
                this.activeYearFirst > this.activeYearSecond &&
                index < this.activeMonthFirst.index
              ) {
                return "background: #F3F5FA; border-radius: 0";
              }
            } else if (this.inSecondYear) {
              /**
               * Если на странице второй активной точки
               */
              if (
                this.activeYearFirst < this.activeYearSecond &&
                index < this.activeMonthSecond.index
              ) {
                return "background: #F3F5FA; border-radius: 0";
              } else if (
                this.activeYearFirst > this.activeYearSecond &&
                index > this.activeMonthSecond.index
              ) {
                return "background: #F3F5FA; border-radius: 0";
              }
            }
          }
        }
        /**
         * Закрашивания страниц, которые входят в промежуток, если между активными страницами есть промежуточные.
         */
      } else if (this.betweenYears) {
        if (this.activeMonthFirst && this.activeMonthSecond) {
          return "background: #F3F5FA; border-radius: 0";
        }
      }
    },
    /**
     * Отвечает за отрисовку главных месяцев.
     */
    CheckMain(list, index) {
      /**
       * Если хотим выбрать одну дату (т.е 1 и 2 точки одинаковы)
       */
      if (
        this.activeMonthFirst.name === list &&
        this.activeMonthSecond.name === list &&
        this.inFirstYear &&
        this.inSecondYear
      ) {
        return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
      }
      /**
       * Если кликаем на одну и туже дату на разных страницах
       */
      if (
        this.activeMonthFirst.name === list &&
        this.activeMonthSecond.name === list &&
        this.activeYearFirst !== this.activeYearSecond
      ) {
        if (this.activeYearFirst < this.activeYearSecond) {
          if (this.inFirstYear) {
            return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
          } else if (this.inSecondYear) {
            return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
          }
        } else {
          if (this.inFirstYear) {
            return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
          } else if (this.inSecondYear) {
            return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
          }
        }
      }
      /**
       * Условия для двух разных точек на разных страницах.
       */
      if (this.activeMonthFirst.name === list) {
        /**
         * Если находимся на странице 1 активной точки
         */
        if (this.inFirstYear) {
          return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
        } else if (this.nowYear !== this.activeYearFirst) {
          /**
           * Если находимся на странице на которой нет 1 активной точки
           */
          if (this.activeMonthFirst && this.activeMonthSecond) {
            /**
             * Условия которые определяют какие точки закрашивать, в зависимости от расположения 2х активных точек
             */
            if (this.ifSecondMore) {
              return "background: #F3F5FA; border-radius: 0";
            } else if (this.ifFirstMore) {
              return "background: #F3F5FA; border-radius: 0";
            }
          }
        }
      } else if (this.activeMonthSecond.name === list) {
        /**
         * Если находимся на странице 2 активной точки
         */
        if (this.inSecondYear) {
          return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
        } else if (this.nowYear !== this.activeYearSecond) {
          /**
           * Если находимся на странице на которой нет 1 активной точки
           */
          if (this.activeMonthFirst && this.activeMonthSecond) {
            /**
             * Условия которые определяют какие точки закрашивать, в зависимости от расположения 2х активных точек
             */
            if (this.ifSecondMore) {
              return "background: #F3F5FA; border-radius: 0";
            } else if (this.ifFirstMore) {
              return "background: #F3F5FA; border-radius: 0";
            }
          }
        }
      } else {
        return this.checkBetween(index);
      }
    },
    disabledMonth(index) {
      let dateMin = this.dateMin.split("-");
      let dateMax = this.maxDate
        ? this.dateMax.split("-")
        : format(new Date(), "yyyy-MM").split("-");
      if (parseInt(this.nowYear) >= dateMin[0] && parseInt(this.nowYear) <= dateMax[0]) {
        if (parseInt(this.nowYear) === parseInt(dateMin[0]) && index + 1 >= parseInt(dateMin[1])) {
          return false;
        }
        if (parseInt(this.nowYear) === parseInt(dateMax[0]) && index + 1 <= parseInt(dateMax[1])) {
          return false;
        }
        if (
          parseInt(this.nowYear) < parseInt(dateMax[0]) &&
          parseInt(this.nowYear) > parseInt(dateMin[0])
        ) {
          return false;
        }
      }
      return true;
    }
  },
  computed: {
    /**
     * Проверка на то, находимся ли мы на промежуточной странице.
     */
    betweenYears() {
      return (
        (this.nowYear > this.activeYearFirst && this.nowYear < this.activeYearSecond) ||
        (this.nowYear < this.activeYearFirst && this.nowYear > this.activeYearSecond)
      );
    },
    /**
     * Проверка на то, находимся ли мы на активных страницах.
     */
    checkMiddleYears() {
      return this.nowYear !== this.activeYearFirst && this.nowYear !== this.activeYearSecond;
    },
    ifFirstMore() {
      return (
        this.activeYearFirst > this.activeYearSecond &&
        (this.activeMonthFirst.index > this.activeMonthSecond.index || this.checkMiddleYears) &&
        this.nowYear <= this.activeYearFirst &&
        this.nowYear >= this.activeYearSecond
      );
    },
    ifSecondMore() {
      return (
        this.activeYearFirst < this.activeYearSecond &&
        (this.activeMonthFirst.index < this.activeMonthSecond.index || this.checkMiddleYears) &&
        this.nowYear >= this.activeYearFirst &&
        this.nowYear <= this.activeYearSecond
      );
    },
    ifFirstSameSecond() {
      return (
        this.activeMonthFirst &&
        this.activeMonthSecond &&
        this.activeMonthFirst.name === this.activeMonthSecond.name
      );
    },
    inFirstYear() {
      return this.nowYear === this.activeYearFirst;
    },
    inSecondYear() {
      return this.nowYear === this.activeYearSecond;
    }
  },
  watch: {
    value() {
      /**
       * Если приходит какое-то значение, то мы его подставляем, и запускаем проверку и отрисовку рэнджа.
       * @type {String}
       */
      if (!this.value.start) {
        return;
      }
      let start = this.value.start[`from_${this.keyAdd}`]
        .split(" ")[0]
        .split("-")
        .reverse()
        .splice(1, 2)
        .join("-");
      let end = this.value.end[`to_${this.keyAdd}`]
        .split(" ")[0]
        .split("-")
        .reverse()
        .splice(1, 2)
        .join("-");
      this.monthRangeValue = `от ${start} до ${end}`;
      this.setValueMonthRange();
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
.month-picker-background {
  max-width: 300px;
  width: 100%;
  position: relative;
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $label-gray;
    margin-bottom: 3px;
    padding-bottom: 0;
  }
  input {
    background: $main-white;
    border: 1.5px solid $input-gray;
    border-radius: 3px;
    color: $main-gray;
    outline: none;
    padding: 7px 6px;
    height: 30px;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
  }
  input::placeholder {
    color: $button-gray;
    font-size: 14px;
    line-height: 16px;
  }
  input:focus {
    border: 1.5px solid $main-blue;
    box-shadow: 0px 1px 5px -2px rgba(18, 132, 255, 0.55);
  }
  input:disabled {
    background: $hover-gray;
    color: $inactive-gray;
    font-weight: 500;
  }

  input:invalid {
    box-shadow: none;
  }

  .invalid-input {
    border: 1px solid $red;
  }

  .invalid-label {
    color: $red;
  }
  #month-picker {
    .month_picker_container {
      position: absolute;
      z-index: 5;
      top: 110%;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      user-select: none;
      z-index: 100;

      .year_select_area {
        display: flex;
        align-items: center;
        padding: 12px 24px;

        .now_year {
          margin: 0 12px;
        }

        .arrow {
          cursor: pointer;
        }

        .right_arrow {
          transform: rotate(180deg);
        }
      }

      .month_select_area {
        ul,
        li {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .month_list_area {
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;

          .month_list {
            width: 60px;
            height: 25px;
            border-radius: 4px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: transparent;
            text-align: center;
            font-size: 14px;
            color: #222;
          }

          .month_list:hover {
            background-color: #f6f6f6;
          }
        }
      }
    }
  }
}
</style>
